@import "./common.scss";
@import "./constants.scss";

html {
    overflow-x: hidden;
}

body {
    color: black;
    background-color: $lightGrey;
    font-family: "Americane";
    font-size: 15px;
    line-height: 1.5;
    margin: 0;
}

h1 {
    font-size: 48px;
}

h2 {
    font-size:36px;
}

h3 {
    font-size:30px;
}

h4 {
    font-size:24px;
}

h5 {
    font-size:20px;
}

h6 {
    font-size:16px;
}

h1, h2, h3, h4, h5, h6 {
    font-weight: 600;
    margin: 10px 0
}

.heading {
    background-color: white;
    padding: 24px 0;
    text-align: center;
}

.splash {
    background-image: url("./public/start/backdrop.jpg");
    background-repeat: no-repeat;
    background-size: cover;
    background-position: center;
    height: 800px;
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;

    @media (max-width:1024px) {
        height: 600px;
    }
    @media (max-width:600px) {
        height: 400px;
    }
    @media (max-width:376px) {
        height: 300px
    }
}

footer {
    color: white;
    background-color: $darkGrey;
    padding-top: 32px;
    padding-bottom: 32px;

    .content {
        display: flex;
        flex-wrap: wrap;
        justify-content: space-around;
        align-items: center;
        margin: 0 12rem;
    }

    .tag {
        padding-left: 8px;
        padding-right: 8px;
    }

    .logo {
        height: 50px;

        @media (max-width: 1024px) {
            display: none;
        }

    }
}

.main {
    display: flex;
    justify-content: center;
}

.column {
    display: flex;
    flex-direction: column;
    max-width: 820px;
    
    @media (max-width:910px) {
        margin: 0 4rem;
    }
    @media (max-width:600px) {
        margin: 0;
    }
}

.grid {
    display: grid;
    justify-content: space-between;
    align-content: space-between;
    grid-template-columns: repeat(2, 50%);
}

.profile {
    color: white;
    background-color: black;
    margin: 16px;
    width: 256px;

    @media (max-width:1024px) {
        width: 263px;
    }
    @media (max-width:910px) {
        width: 320px;
    }

    img {
        width: 100%;
    }

    header {
        text-align: left;
    }
}

.people {
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
}

.tag {
    background-color: $lightGrey;
    color: black;
    text-align: center;
}

a.tag {
    text-decoration: none;

    &:hover {
        color: white;
        background-color: black;
        text-decoration: none;
    }
    
    &.black:hover {
        color: black;
        background-color: $lightGrey;
        text-decoration: none;
    }
}

.black {
    color: white;
    background-color: black;
}

.top {
    color: white;
    background-color: black;
    display: flex;
    justify-content: center;
    overflow: hidden;
    position: sticky;
    top: 0;
    z-index: 1;

    @media (min-width: 600px) {
        .menu {
            flex-direction: row-reverse;

            .toggle {
                display: none;
            }
        }
    }
    
    .menu {
        display: flex;
        align-items: center;
        justify-content: space-between;
        font-size: 14px;
        padding: 0 16rem;

        @media (max-width: 1024px) {
            padding: 0 12rem;
        }
        @media (max-width: 910px) {
            padding: 0 10rem;
        }
        @media (max-width:600px) {
            padding: 0;
            width:100%;
        }

        .bar .icon {
            height: 24px;
            padding: 6px;
        }

        .items .item {
            color: white;
            padding: 8px 16px;
            height: 100%;
            text-decoration: none;
            white-space: nowrap;

            &:hover {
                background-color: $lightGrey;
                color: black;
            }
        }
    }

    .facebook {
        display: flex;
        justify-content: center;
        align-items: center;
        padding: 0 8px;
    }    
}

.splash {
    position: relative;

    h1 {
        color: white;
        font-size: 64px;

        @media (max-width:1024px) {
            font-size: 36px;
        }
        @media (max-width:910px) {
            font-size: 24px;
        }
        @media (max-width:600px) {
            font-size: 18px;
        }
        @media (max-width:376px) {
            font-size: 16px;
        }
    }

    .backdrop {
        height: 100%;
        width: 100%;
    }

    .tag {
        padding: 0 16px;
    }

    .content {
        position: absolute;
        padding: 12px 24px;

        .actions {
            display: flex;
            flex-wrap: wrap;
        }
    }
}

@media (max-width:600px) {
    .desktop {
        display: none !important;
    }

    .top {
        .menu {
            display: flex;
            flex-direction: column;

            .bar {
                display: flex;
                justify-content: space-between;
                width: 100%;

                .toggle {
                    display: flex;
                    align-items: center;
                    text-decoration: none;
                    cursor: pointer;
                }
            }
                        
            .items {
                display: flex;
                flex-direction: column;
                width: 100%;
        
                &.collapsed {
                    display: none;
                }
            }
        }
    }

    .subtitle {
        display: none;
    }

    .mobile {
        display: block;
        width: 100%;
    }

    .grid {
        display: flex;
        flex-direction: column;
    }
}

section {
    color: black;
    background-color: white;
    margin: 16px;
    padding: 16px;

    @media (max-width:600px) {
        margin: 0;
    }

    header {
        padding: 8px 16px;

        h4 {
            text-transform: uppercase;
        }

        .subtitle {
            opacity: 0.60;
        }

        img {
            height: 100%;
            width: 100%;
            padding-top: 16px;
            padding-bottom: 16px; 
        }
    }

    header.black {
        text-align: left;
    }

    ul {
        list-style-type: none;
        padding: 0;
        margin: 0;

        li {
            padding: 16px;
        }
    }

    p {
        padding: 0 16px;
    }
}

a {
    color: black;
    text-decoration: none;

    &:hover {
        text-decoration: underline;
    }
}

.logo {
    height: 10em;

    @media (max-width:1024px) {
        height: 7em;
    }
    @media (max-width:600px) {
        height: 4em;
    }
    @media (max-width:376px) {
        height: 3em;
    }

    &.big {
        height: 14em;

        @media (max-width:1024px) {
            height: 8em;
        }
        @media (max-width:600px) {
            height: 4.7em;
        }
        @media (max-width:376px) {
            height: 3em;
        }
    }

    &.small {
        height: 6em;
    }

    &.opaque {
        padding: 1em 2em;
        background-color: white;
        opacity: 0.60;
    }
}

.button {
    &, &.light {
        color: black;
        background-color: white;
    }

    &.dark {
        color: white;
        background-color: black;
    }


    padding: 12px 24px;
    margin-right: 2em;
    border: none;
    display: inline-block;
    vertical-align: middle;
    text-decoration: none;
    text-align: center;
    cursor: pointer;
    white-space: nowrap;
    letter-spacing: 4px;

    opacity: 0.90;

    font-size: 18px;

    &:hover {
        opacity: 1;
        text-decoration: none;
    }
}

.form {
    .help {
        font-style: italic;
        margin-top: 0;
    }

    .fields {
        display: flex;
        flex-direction: row;
        flex-wrap: wrap;

        .field {
            display: flex;
            
            @media (max-width:600px) {
                padding: 4px;
                width: 100%;
            }

            &, &.column {
                flex-direction: column;
            }

            &.row {
                flex-direction: row;
            }

            label {
                font-weight: 600;
            }

            &.has-error {
                input, select {
                    border: 1px solid red;
                }
            }
        }
    }

    .errors {
        color: red;
    }

    .actions {
        display: flex;

        @media (max-width:600px) {
            flex-direction: column;

            .button {
                width: 100%;
            }
        }
    }
}

ul.list {
    list-style-type: disc;
    padding: 0;
    padding-left: 2rem;

    li {
        padding: 0.2rem;
    }
}

.card {
    display: grid;
    grid-template-columns: 325px auto;

    border: 1px solid gainsboro;
    margin-bottom: 2rem;

    
    .card-picture {
        height: 325px;
        img {
            width: 325px;
        }
    }

    .card-content {
        display: flex;
        flex-direction: column;
        
        padding: 1rem 1.4rem;
        
        .description {
            p {
                padding: 0;
                margin: 0.6rem;

                &.muted  {
                    color: gray;
                }
            }
        }
    }

    .card-actions {
        display: flex;
        flex-direction: column;
        justify-content: flex-end;
        flex: 1;

        .button {
            align-self :flex-start;
        }
    }

    h1, h2, h3, h4, h5, h6 {
        margin: 0;
    }
}

.loading {
    border: 0.4em solid $lightGrey;
    border-top-color: black;
    border-radius: 50%;
    width: 2em;
    height: 2em;
    animation: spin 2s linear infinite;
}

@keyframes spin {
    0% { transform: rotate(0deg); }
    100% { transform: rotate(360deg); }
}

.loading-wrapper {
    display: flex;
    justify-content: center;
    width: 100%;
}
