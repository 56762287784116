html {
  overflow-x: hidden;
}

body {
  color: #000;
  background-color: #f1f1f1;
  margin: 0;
  font-family: Americane;
  font-size: 15px;
  line-height: 1.5;
}

h1 {
  font-size: 48px;
}

h2 {
  font-size: 36px;
}

h3 {
  font-size: 30px;
}

h4 {
  font-size: 24px;
}

h5 {
  font-size: 20px;
}

h6 {
  font-size: 16px;
}

h1, h2, h3, h4, h5, h6 {
  margin: 10px 0;
  font-weight: 600;
}

.heading {
  text-align: center;
  background-color: #fff;
  padding: 24px 0;
}

.splash {
  background-image: url("backdrop.2eab3f1d.jpg");
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  width: 100%;
  height: 800px;
  display: flex;
}

@media (width <= 1024px) {
  .splash {
    height: 600px;
  }
}

@media (width <= 600px) {
  .splash {
    height: 400px;
  }
}

@media (width <= 376px) {
  .splash {
    height: 300px;
  }
}

footer {
  color: #fff;
  background-color: #616161;
  padding-top: 32px;
  padding-bottom: 32px;
}

footer .content {
  flex-wrap: wrap;
  justify-content: space-around;
  align-items: center;
  margin: 0 12rem;
  display: flex;
}

footer .tag {
  padding-left: 8px;
  padding-right: 8px;
}

footer .logo {
  height: 50px;
}

@media (width <= 1024px) {
  footer .logo {
    display: none;
  }
}

.main {
  justify-content: center;
  display: flex;
}

.column {
  flex-direction: column;
  max-width: 820px;
  display: flex;
}

@media (width <= 910px) {
  .column {
    margin: 0 4rem;
  }
}

@media (width <= 600px) {
  .column {
    margin: 0;
  }
}

.grid {
  grid-template-columns: repeat(2, 50%);
  place-content: space-between;
  display: grid;
}

.profile {
  color: #fff;
  background-color: #000;
  width: 256px;
  margin: 16px;
}

@media (width <= 1024px) {
  .profile {
    width: 263px;
  }
}

@media (width <= 910px) {
  .profile {
    width: 320px;
  }
}

.profile img {
  width: 100%;
}

.profile header {
  text-align: left;
}

.people {
  flex-wrap: wrap;
  justify-content: center;
  display: flex;
}

.tag {
  color: #000;
  text-align: center;
  background-color: #f1f1f1;
}

a.tag {
  text-decoration: none;
}

a.tag:hover {
  color: #fff;
  background-color: #000;
  text-decoration: none;
}

a.tag.black:hover {
  color: #000;
  background-color: #f1f1f1;
  text-decoration: none;
}

.black {
  color: #fff;
  background-color: #000;
}

.top {
  color: #fff;
  z-index: 1;
  background-color: #000;
  justify-content: center;
  display: flex;
  position: sticky;
  top: 0;
  overflow: hidden;
}

@media (width >= 600px) {
  .top .menu {
    flex-direction: row-reverse;
  }

  .top .menu .toggle {
    display: none;
  }
}

.top .menu {
  justify-content: space-between;
  align-items: center;
  padding: 0 16rem;
  font-size: 14px;
  display: flex;
}

@media (width <= 1024px) {
  .top .menu {
    padding: 0 12rem;
  }
}

@media (width <= 910px) {
  .top .menu {
    padding: 0 10rem;
  }
}

@media (width <= 600px) {
  .top .menu {
    width: 100%;
    padding: 0;
  }
}

.top .menu .bar .icon {
  height: 24px;
  padding: 6px;
}

.top .menu .items .item {
  color: #fff;
  white-space: nowrap;
  height: 100%;
  padding: 8px 16px;
  text-decoration: none;
}

.top .menu .items .item:hover {
  color: #000;
  background-color: #f1f1f1;
}

.top .facebook {
  justify-content: center;
  align-items: center;
  padding: 0 8px;
  display: flex;
}

.splash {
  position: relative;
}

.splash h1 {
  color: #fff;
  font-size: 64px;
}

@media (width <= 1024px) {
  .splash h1 {
    font-size: 36px;
  }
}

@media (width <= 910px) {
  .splash h1 {
    font-size: 24px;
  }
}

@media (width <= 600px) {
  .splash h1 {
    font-size: 18px;
  }
}

@media (width <= 376px) {
  .splash h1 {
    font-size: 16px;
  }
}

.splash .backdrop {
  width: 100%;
  height: 100%;
}

.splash .tag {
  padding: 0 16px;
}

.splash .content {
  padding: 12px 24px;
  position: absolute;
}

.splash .content .actions {
  flex-wrap: wrap;
  display: flex;
}

@media (width <= 600px) {
  .desktop {
    display: none !important;
  }

  .top .menu {
    flex-direction: column;
    display: flex;
  }

  .top .menu .bar {
    justify-content: space-between;
    width: 100%;
    display: flex;
  }

  .top .menu .bar .toggle {
    cursor: pointer;
    align-items: center;
    text-decoration: none;
    display: flex;
  }

  .top .menu .items {
    flex-direction: column;
    width: 100%;
    display: flex;
  }

  .top .menu .items.collapsed, .subtitle {
    display: none;
  }

  .mobile {
    width: 100%;
    display: block;
  }

  .grid {
    flex-direction: column;
    display: flex;
  }
}

section {
  color: #000;
  background-color: #fff;
  margin: 16px;
  padding: 16px;
}

@media (width <= 600px) {
  section {
    margin: 0;
  }
}

section header {
  padding: 8px 16px;
}

section header h4 {
  text-transform: uppercase;
}

section header .subtitle {
  opacity: .6;
}

section header img {
  width: 100%;
  height: 100%;
  padding-top: 16px;
  padding-bottom: 16px;
}

section header.black {
  text-align: left;
}

section ul {
  margin: 0;
  padding: 0;
  list-style-type: none;
}

section ul li {
  padding: 16px;
}

section p {
  padding: 0 16px;
}

a {
  color: #000;
  text-decoration: none;
}

a:hover {
  text-decoration: underline;
}

.logo {
  height: 10em;
}

@media (width <= 1024px) {
  .logo {
    height: 7em;
  }
}

@media (width <= 600px) {
  .logo {
    height: 4em;
  }
}

@media (width <= 376px) {
  .logo {
    height: 3em;
  }
}

.logo.big {
  height: 14em;
}

@media (width <= 1024px) {
  .logo.big {
    height: 8em;
  }
}

@media (width <= 600px) {
  .logo.big {
    height: 4.7em;
  }
}

@media (width <= 376px) {
  .logo.big {
    height: 3em;
  }
}

.logo.small {
  height: 6em;
}

.logo.opaque {
  opacity: .6;
  background-color: #fff;
  padding: 1em 2em;
}

.button {
  vertical-align: middle;
  text-align: center;
  cursor: pointer;
  white-space: nowrap;
  letter-spacing: 4px;
  opacity: .9;
  border: none;
  margin-right: 2em;
  padding: 12px 24px;
  font-size: 18px;
  text-decoration: none;
  display: inline-block;
}

.button, .button.light {
  color: #000;
  background-color: #fff;
}

.button.dark {
  color: #fff;
  background-color: #000;
}

.button:hover {
  opacity: 1;
  text-decoration: none;
}

.form .help {
  margin-top: 0;
  font-style: italic;
}

.form .fields {
  flex-flow: wrap;
  display: flex;
}

.form .fields .field {
  display: flex;
}

@media (width <= 600px) {
  .form .fields .field {
    width: 100%;
    padding: 4px;
  }
}

.form .fields .field, .form .fields .field.column {
  flex-direction: column;
}

.form .fields .field.row {
  flex-direction: row;
}

.form .fields .field label {
  font-weight: 600;
}

.form .fields .field.has-error input, .form .fields .field.has-error select {
  border: 1px solid red;
}

.form .errors {
  color: red;
}

.form .actions {
  display: flex;
}

@media (width <= 600px) {
  .form .actions {
    flex-direction: column;
  }

  .form .actions .button {
    width: 100%;
  }
}

ul.list {
  padding: 0 0 0 2rem;
  list-style-type: disc;
}

ul.list li {
  padding: .2rem;
}

.card {
  border: 1px solid #dcdcdc;
  grid-template-columns: 325px auto;
  margin-bottom: 2rem;
  display: grid;
}

.card .card-picture {
  height: 325px;
}

.card .card-picture img {
  width: 325px;
}

.card .card-content {
  flex-direction: column;
  padding: 1rem 1.4rem;
  display: flex;
}

.card .card-content .description p {
  margin: .6rem;
  padding: 0;
}

.card .card-content .description p.muted {
  color: gray;
}

.card .card-actions {
  flex-direction: column;
  flex: 1;
  justify-content: flex-end;
  display: flex;
}

.card .card-actions .button {
  align-self: flex-start;
}

.card h1, .card h2, .card h3, .card h4, .card h5, .card h6 {
  margin: 0;
}

.loading {
  border: .4em solid #f1f1f1;
  border-top-color: #000;
  border-radius: 50%;
  width: 2em;
  height: 2em;
  animation: 2s linear infinite spin;
}

@keyframes spin {
  0% {
    transform: rotate(0);
  }

  100% {
    transform: rotate(360deg);
  }
}

.loading-wrapper {
  justify-content: center;
  width: 100%;
  display: flex;
}
/*# sourceMappingURL=public.css.map */
